import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				CycleCare Pro
			</title>
			<meta name={"description"} content={"Ваша надійна майстерня з ремонту велосипедів"} />
			<meta property={"og:title"} content={"CycleCare Pro"} />
			<meta property={"og:description"} content={"Ваша надійна майстерня з ремонту велосипедів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
		</Helmet>
		<Components.Header2 />
		<Section padding="100px 0 100px 0" background="--color-darkL2" quarkly-title="FAQ-7">
			<Text margin="0px 0px 2rem 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Поширені запитання
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							1. Які види ремонту велосипедів ви пропонуєте?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Ми пропонуємо широкий спектр ремонтних послуг, включаючи базове обслуговування, ремонт гальм, перемикачів швидкостей, заміну шин, ремонт підвіски, налаштування рульового управління та багато іншого.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							2. Скільки часу займає ремонт велосипеда?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Час ремонту залежить від складності роботи. Базове обслуговування може зайняти від кількох годин до одного дня, тоді як більш складні ремонти можуть вимагати декількох днів. Ми завжди повідомляємо клієнтів про орієнтовний час ремонту після діагностики.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							3. Чи потрібно записуватись на ремонт заздалегідь?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ми рекомендуємо записуватись заздалегідь, щоб забезпечити найшвидше та найякісніше обслуговування. Ви можете зробити це через наш вебсайт або зателефонувати нам безпосередньо.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							4. Чи надаєте ви гарантію на виконані роботи?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ми надаємо гарантію на всі виконані ремонтні роботи. Термін гарантії залежить від типу виконаних робіт, але зазвичай складає від 3 до 6 місяців. Деталі гарантії можна уточнити під час здачі велосипеда в ремонт.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							5. Чи можете ви підібрати та замовити запчастини для мого велосипеда?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ми працюємо з багатьма постачальниками запчастин та можемо підібрати та замовити необхідні деталі для вашого велосипеда. Ми також можемо порекомендувати альтернативи, якщо оригінальні запчастини недоступні.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						<Strong>
							6. Чи можна залишити велосипед на зберігання після ремонту?
						</Strong>
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="--light">
						Так, ми надаємо послуги зберігання велосипедів після ремонту. Ви можете залишити велосипед у нашому сервісі на короткий або тривалий термін. Будь ласка, зв'яжіться з нами для деталей та умов зберігання.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Header2 />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});